#loader {
   width: 100%;
   height: 100vh;
   position: fixed;
   top: 0;
   background-color: rgb(255, 255, 255);
   display: flex;
   align-items: center;
   justify-content: center;

   #cube {
      width: 200px;
      height: 200px;
      background-color: royalblue;
      animation: an 1s linear infinite;

   }
}

@keyframes an {
   0% {
      border-radius: 0px;
      transform: rotate(0deg);
   }

   50% {
      border-radius: 100px;
      transform: rotate(180deg);

   }

   100% {
      border-radius: 0px;
      transform: rotate(0deg);

   }
}